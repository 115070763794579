import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const PlanService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plan_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_plan: form.id_cat_plan,
			id_cat_hora_persona: form.id_cat_hora_persona,
			nombre: form.nombre,
			precio: form.precio,
			dias_gratis: form.dias_gratis,
			costo_dia: form.costo_dia,
			costo_hora: form.costo_hora,
			visita_ilimitada: form.visita_ilimitada ? 1 : 0,
			cant_impresion_bn: form.cant_impresion_bn,
			cant_impresion_color: form.cant_impresion_color,
			horas_sala_4p: form.horas_sala_4p,
			horas_sala_6_8p: form.horas_sala_6_8p,
			horas_sala_12p:  form.horas_sala_12p,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plan_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_plan: form.id_cat_plan,
			id_cat_hora_persona: form.id_cat_hora_persona,
			nombre: form.nombre,
			precio: form.precio,
			dias_gratis: form.dias_gratis,
			costo_dia: form.costo_dia,
			costo_hora: form.costo_hora,
			visita_ilimitada: form.visita_ilimitada ? 1 : 0,
			cant_impresion_bn: form.cant_impresion_bn,
			cant_impresion_color: form.cant_impresion_color,
			horas_sala_4p: form.horas_sala_4p,
			horas_sala_6_8p: form.horas_sala_6_8p,
			horas_sala_12p:  form.horas_sala_12p,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plan_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_grupo) => {
		let params = {
			id_cat_grupo: id_cat_grupo
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Plan_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
