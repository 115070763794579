import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {HoraPersonaService} from "../../../../../services/_Cat/HoraPersonaService/HoraPersonaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../../Include/MiniComponents/GlobalComponent";
import {AttachMoney, Person, QueryBuilder, Today} from "@material-ui/icons";
import {CatService} from "../../../../../services/_Cat/CatService/CatService";

class ModalHoraPersona extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			id_cat_hora_persona: (props.item || {}).id_cat_hora_persona,
			costo: '',
			horas: '',
			personas: '',
			activo: true,
			
			list_cat_dia: []
		};
		
		this.ListDias();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	handleChange2 = (e, variable, campo, date, input) => {
		if ((date && input) || input) {
			this.setState({
				[input]: date
			});
		} else {
			const {value, name, checked, type} = e.target;
			if (variable && campo) {
				let key = Number(name.split('__')[1]);
				let arr_temp = this.state[variable];
				for (let i = 0; i < arr_temp.length; i++) {
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				this.setState({
					[variable]: arr_temp
				});
			} else {
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
			}
		}
	};
	
	handleChangeCheckPS = (item, valor) => {
		let lista = FieldsJs.Copy(this.state.lista_facturacion_producto);
		let bandTodo = false;
		let cont = 0;
		lista.map((value) => {
			if (Number(item.id_facturacion_producto) === Number(value.id_facturacion_producto)) {
				value.check = !value.check;
			}
		})
		
		lista.map((value) => {
			if (value.check) {
				cont++;
			}
		})
		
		console.log('CONT', cont);
		if (cont === lista.length) {
			this.setState({
				checkPS: true
			})
		} else if (cont < lista.length) {
			this.setState({
				checkPS: false
			})
		}
		
		this.setState({
			lista_facturacion_producto: lista
		})
		console.log("ITEM", item);
	};
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_cat_hora_persona > 0) {
			HoraPersonaService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			HoraPersonaService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			costo: '',
			horas: '',
			personas: '',
			activo: true,
		});
		let dias = FieldsJs.Copy(this.state.list_cat_dia);
		for (let i = 0; i < dias.length; i++) {
			dias[i].checked = false;
		}
		this.setState({list_cat_dia: dias});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_hora_persona: item.id_cat_hora_persona || '',
			costo: item.costo || '',
			horas: item.horas || '',
			personas: item.personas || '',
			activo: (item.activo === 1),
		});
		let dias = FieldsJs.Copy(this.state.list_cat_dia);
		for (let i = 0; i < dias.length; i++) {
			switch (dias[i].id_cat_dia) {
				case 1:
					dias[i].checked = item.domingo;
					break;
				case 2:
					dias[i].checked = item.lunes;
					break;
				case 3:
					dias[i].checked = item.martes;
					break;
				case 4:
					dias[i].checked = item.miercoles;
					break;
				case 5:
					dias[i].checked = item.jueves;
					break;
				case 6:
					dias[i].checked = item.viernes;
					break;
				case 7:
					dias[i].checked = item.sabado;
					break;
			}
		}
		this.setState({list_cat_dia: dias});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_hora_persona: item.id_cat_hora_persona || '',
			costo: item.costo || '',
			horas: item.horas || '',
			personas: item.personas || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	ListDias() {
		CatService.ListDia().then((response) => {
			this.setState({
				list_cat_dia: response.data
			});
			
		});
		
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_hora_persona}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Hora Persona'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField style={{marginTop: '3px'}}
										           autoFocus
										           margin="dense"
										           name="personas"
										           label="Cantidad de Personas"
										           type="text"
										           fullWidth
										           defaultValue={this.state.personas}
										           onChange={this.handleChange}
										           disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{/*<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Today className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											select
											fullWidth
											onChange={this.handleChange}
											SelectProps={{
												native: true,
												MenuProps: {},
											}}
											
											name="id_cat_dia"
											label="Día"
											value={this.state.id_cat_dia}
											required
										>
											<option key={0} value={''}>&nbsp;</option>
											{this.state.list_cat_dia.map(option => (
												<option key={option.id_cat_dia}
												        value={option.id_cat_dia}>
													{option.dia}
												</option>
											))}
										</TextField>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item className={'text-left'} xs={6} sm={6} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AttachMoney className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="costo"
											onChange={this.handleChange}
											label="Precio"
											autoComplete={'off'}
											value={this.state.costo}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={6} sm={3} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<QueryBuilder className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="horas"
											onChange={this.handleChange}
											onKeyPress={EnteroSolo}
											label="Horas"
											autoComplete={'off'}
											value={this.state.horas}
											disabled={this.state.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{this.state.list_cat_dia.length > 0 ? this.state.list_cat_dia.map((item, key) =>
								(<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
									<FormGroup row className={'margin-3-L'}>
										<FormControlLabel
											className={'h-30-px px-14 text-left'}
											control={
												<Checkbox
													fontSize="small"
													type="checkbox"
													color="primary"
													name={'checked__' + key}
													onChange={(e) => this.handleChange2(e, 'list_cat_dia', 'checked')}
													checked={!!item.checked}
													disabled={this.state.tipo === 'view'}
												/>
											}
											label={item.dia}
											labelPlacement="end"
										/>
									</FormGroup>
								</Grid>)
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_hora_persona}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalHoraPersona.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalHoraPersona;
