import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {EstadoCuentasService} from "../../../../services/EstadoCuentasService/EstadoCuentasService";
import {CONFIG} from "../../../../settings/Config/Config";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import {FieldsJs} from "../../../../settings/General/General";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el sexo ${item.sexo}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				EstadoCuentasService.Eliminar(item.id_cat_sexo).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	sumTotales = (tipo) => {
		let lista = FieldsJs.Copy(this.props.lista);
		let total = 0;
		switch (tipo) {
			case 0:
				for (let i = 0; i < lista.length; i++) {
					if (FieldsJs.inArray(this.props.ids_cat_forma_pago, lista[i].id_cat_forma_pago)) {
						if (lista[i].id_compra > 0) {
							total = total + Number(lista[i].total);
						}
					}
				}
				break;
			case 1:
				for (let i = 0; i < lista.length; i++) {
					if (FieldsJs.inArray(this.props.ids_cat_forma_pago, lista[i].id_cat_forma_pago)) {
						if (lista[i].id_venta > 0 || lista[i].id_fondeo_cuenta > 0) {
							total = total + Number(lista[i].total);
						}
					}
				}
				break;
			case 2:
				for (let i = 0; i < lista.length; i++) {
					if (FieldsJs.inArray(this.props.ids_cat_forma_pago, lista[i].id_cat_forma_pago)) {
						if (lista[i].saldo > 0) {
							total = total + Number(lista[i].saldo);
						}
					}
				}
				break;
		}
		
		return total;
	};
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-mark'}>
					<TableHead>
						<TableRow>
							<TableCell component="th" align={'center'} className={'w-40-px padding-20-L padding-20-R'}>Fecha</TableCell>
							<TableCell component="th" align={'left'}>Concepto</TableCell>
							<TableCell component="th" align={'left'}>Referencia</TableCell>
							<TableCell component="th" align={'left'}>Cargo</TableCell>
							<TableCell component="th" align={'left'}>Abono</TableCell>
							<TableCell component="th" align={'left'}>Saldo</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => {
							return (
								<Fragment key={key}>
									{FieldsJs.inArray(this.props.ids_cat_forma_pago, item.id_cat_forma_pago) ? (
										<TableRow>
											<TableCell align={'left'}>{DateFormat.FormatText(item.fecha_alta)}</TableCell>
											<TableCell align={'left'}>{item.concepto || 'Sin registrar'}</TableCell>
											<TableCell align={'left'}>{item.referencia}</TableCell>
											<TableCell align={'left'}>{item.id_compra > 0 ? '$' + FieldsJs.Currency(item.total) : '$' + FieldsJs.Currency(0)}</TableCell>
											<TableCell align={'left'}>{item.id_fondeo_cuenta > 0 || item.id_venta > 0 ? '$' + FieldsJs.Currency(item.total) : '$' + FieldsJs.Currency(0)}</TableCell>
											<TableCell align={'left'}>${FieldsJs.Currency(Number(this.props.tap) === 2 ? item.saldo_general:item.saldo)}</TableCell>
										</TableRow>
									) : null
									}
								</Fragment>
							)
						})}
						<TableRow>
							<TableCell colSpan={3} align={'left'} className={'text-right'}><strong>TOTAL</strong></TableCell>
							<TableCell align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(0))}</strong></TableCell>
							<TableCell colSpan={2} align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(1))}</strong></TableCell>
							{/*<TableCell align={'left'}><strong>${FieldsJs.Currency(this.sumTotales(2))}</strong></TableCell>*/}
						</TableRow>
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	tap: PropTypes.number,
	ids_cat_forma_pago: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ListaTabla;
