import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {PlanesService} from "../../../../services/PlanesService/PlanesService";
import {BotonAccionAbrirModal, BotonAccionFooterModal} from "../../../Include/MiniComponents/GlobalComponent";
import AutoCompleteCliente from "../../OrdenDeVenta/Includes/AutoCompleteCliente";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import {PlanService} from "../../../../services/_Cat/PlanService/PlanService";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import bg_plans1 from '../../../../assets/img/planes/plan1.png';
import bg_plans2 from '../../../../assets/img/planes/plan2.png';
import bg_plans3 from '../../../../assets/img/planes/plan3.png';
import bg_plans4 from '../../../../assets/img/planes/plan4.png';
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {DateRangeOutlined} from "@material-ui/icons";


const formulario = {
	id_cliente: null,
	id_cat_tipo_persona: 1,
	tipo_persona: 'fisica',
	razon_social: '',
	nombre: '',
	apellido_paterno: '',
	apellido_materno: '',
	correo_electronico: '',
	telefono: '',
	celular: '',
	rfc: '',
	activo: true,
	
	id_cliente_direccion: null,
	calle: '',
	numero_exterior: '',
	numero_interior: '',
	codigo_postal: '',
	colonia: '',
	id_cat_estado: '',
	id_cat_municipio: '',
	estado: '',
	municipio: '',
	
	ids_cliente_como_te_enteraste: []
};


class ModalPlanesDesign extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_plan: (props.item || {}).id_plan,
			
			id_cat_plan: '',
			nombre: '',
			precio: '',
			id_cliente: '',
			fecha_inicio: new Date(),
			fecha_fin: new Date(),
			
			activo: true,
			
			
			form: formulario,
			form_seleccionado: {},
			
			clientes_datos_suggestions: [],
			
		};
		
		//this.ListarDeCliente();
		//this.ListarPlanes();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListarDeCliente = () => {
		ClienteService.Listar({activo: 1}, {}).then((response) => {
			
			let clt = response.data;
			
			let form = this.state.form;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form.id_cliente)) {
					form = clt[i];
				}
			}
			
			let form_seleccionado = this.state.form_seleccionado;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form_seleccionado.id_cliente)) {
					form_seleccionado = clt[i];
				}
			}
			
			this.setState({
				clientes_datos_suggestions: clt,
				form: form,
				form_seleccionado: form_seleccionado,
			});
		});
	};
	
	save = () => {
		this.props.addPlan(this.state);
		this.close();
		/*if (this.state.id_plan > 0) {
			PlanesService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			PlanesService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}*/
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_plan: '',
			id_cat_plan: '',
			nombre: '',
			precio: '',
			id_cliente: '',
			fecha_inicio: new Date(),
			fecha_fin: new Date(),
			
			activo: true,
		});
		let copyPlan = this.props.cat_planes;
		for (let i = 0; i < copyPlan.length; i++) {
			copyPlan[i].selected = false;
		}
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_plan: item.id_plan || '',
			id_cat_plan: item.id_cat_plan || '',
			nombre: item.nombre || '',
			precio: item.precio || '',
			id_cliente: item.id_cliente || '',
			fecha_inicio: item.fecha_inicio || new Date(),
			fecha_fin: item.fecha_fin || new Date(),
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_plan: item.id_plan || '',
			id_cat_plan: item.id_cat_plan || '',
			nombre: item.nombre || '',
			precio: item.precio || '',
			id_cliente: item.id_cliente || '',
			fecha_inicio: item.fecha_inicio || new Date(),
			fecha_fin: item.fecha_fin || new Date(),
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	handleChangeSingle = (e, name) => {
		console.log(name, e);
		
		let form = FieldsJs.Copy(formulario);
		
		for (let i = 0; i < this.state.clientes_datos_suggestions.length; i++) {
			if (this.state.clientes_datos_suggestions[i].id_cliente === e.value) {
				for (let key in formulario) {
					form[key] = this.state.clientes_datos_suggestions[i][key];
				}
			}
		}
		
		this.setState({
			[name]: e.value,
			form: FieldsJs.Copy(form),
			form_seleccionado: FieldsJs.Copy(form)
		});
	};
	
	selectedPlan = (item) => {
		console.log(item);
		
		let copyPlan = this.props.cat_planes;
		
		for (let i = 0; i < copyPlan.length; i++) {
			if (copyPlan[i].id_cat_plan === item.id_cat_plan) {
				copyPlan[i].selected = true;
			} else {
				copyPlan[i].selected = false;
			}
		}
		
		this.setState({
			id_cat_plan: item.id_cat_plan,
			nombre: item.nombre,
			precio: item.precio,
		});
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		const bg_plan1 = {
			backgroundImage: `url(${bg_plans1})`,
			backgroundSize: 'contain',
			height: '150px',
			width: '100%'
		};
		const bg_plan2 = {
			backgroundImage: `url(${bg_plans2})`,
			backgroundSize: 'contain',
			height: '150px',
			width: '100%'
		};
		const bg_plan3 = {
			backgroundImage: `url(${bg_plans3})`,
			backgroundSize: 'contain',
			height: '150px',
			width: '100%'
		};
		const bg_plan4 = {
			backgroundImage: `url(${bg_plans4})`,
			backgroundSize: 'contain',
			height: '150px',
			width: '100%'
		};
		
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					{/*<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'sexo'}
						/>
					</DialogTitle>*/}
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							{/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<AutoCompleteCliente
									handleChangeSingle={this.handleChangeSingle}
									suggestions={this.state.clientes_datos_suggestions}
									value={this.state.id_cliente}
									name={'id_cliente'} id={'id_cliente'}
									label={'nombre_completo'}
								/>
							</Grid>*/}
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<div className={'vertical-inline h-100'}>
									<Typography className={'v-center'} style={{fontWeight: 'bold', color: 'gray'}}>
										Selecciona un plan y asigna fecha inicio y fin:
									</Typography>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<MDDatePicker
									type={'range'}
									component={(
										<TextField
											fullWidth
											label="Plan a partir de:"
											value={(this.state.fecha_inicio && this.state.fecha_fin) ? (DateFormat.FormatText(this.state.fecha_inicio) + " al " + DateFormat.FormatText(this.state.fecha_fin)) : ''}
											readOnly
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton aria-label="Plan a partir de:">
															<DateRangeOutlined/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									)}
									startDate={this.state.fecha_inicio}
									endDate={this.state.fecha_fin}
									onChange={(range) => {
										this.setState({
											fecha_inicio: range.startDate,
											fecha_fin: range.endDate,
										});
									}}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={2}>
									{this.props.cat_planes.map((item, key) => (
										<Grid key={key} item xs={12} sm={12} md={3} lg={3} xl={3}>
											<div className={'w-100 w-100 margin-10 cursor-pointer'}>
												<Card className={item.selected ? 'border-selected' : ''} onClick={() => this.selectedPlan(item)}>
													{item.id_cat_plan === 1 ? <div style={bg_plan1}></div> : null}
													{item.id_cat_plan === 2 ? <div style={bg_plan2}></div> : null}
													{item.id_cat_plan === 3 ? <div style={bg_plan3}></div> : null}
													{item.id_cat_plan > 3 ? <div style={bg_plan4}></div> : null}
													
													<div className={'padding-10 min-h-300-px'}>
														<p className={'px-13'} style={{margin: '10px', color: '#961f1f', fontWeight: 'bold', textAlign: 'center'}}>{item.nombre}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Precio: </strong> ${item.precio} mensuales.</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Visitas Gratis: </strong>{item.dias_gratis === 1 ? '1 día.' : 'Ilimitadas.'}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Costo por día: </strong> ${item.costo_dia}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Costo por hora: </strong> ${item.costo_hora}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Sala para 4 personas: </strong>{item.horas_sala_4p} {item.horas_sala_4p === 1 ? 'hr.' : 'hrs.'}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Sala para 6-8 personas: </strong>{item.horas_sala_6_8p} {item.horas_sala_6_8p === 1 ? 'hr.' : 'hrs.'}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Sala para 12 personas: </strong>{item.horas_sala_12p} {item.horas_sala_12p === 1 ? 'hr.' : 'hrs.'}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Impresiones b/n: </strong>{item.cant_impresion_bn}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Impresiones a color: </strong>{item.cant_impresion_color}</p>
														<p className={'px-13'} style={{margin: '0px', marginTop: '3px'}}><strong>Amenidades: </strong>Café, agua y té ilimitado. Acceso a internet, conexión eléctrica, limpieza diaria del lugar. Servicio de
															sanitarios.</p>
													</div>
												</Card>
											</div>
										</Grid>
									))}
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
								<br/>
								<br/>
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPlanesDesign.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	addPlan: PropTypes.func.isRequired,
	cat_planes: PropTypes.array.isRequired,
};

export default ModalPlanesDesign;
