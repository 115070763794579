import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import Typography from "@material-ui/core/Typography";
import Fab from '@material-ui/core/Fab';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import EventNote from '@material-ui/icons/EventNote';
import Sync from '@material-ui/icons/Sync';
import ListaTabla from './Includes/ListaTabla';

import {VentasService} from '../../../services/VentasService/VentasService';
import {PopupService} from '../../../settings/PoPup/PoPup';
import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import Add from '@material-ui/icons/Add';
import Zoom from "@material-ui/core/Zoom";
import $State from "../../../settings/$State/$State";
import {HttpRequest} from "../../../settings/HttpRequest/HttpRequest";
import ModalDisenador from "./Includes/ModalDisenador";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import {OrdenDeVentaService} from "../../../services/OrdenDeVentaService/OrdenDeVentaService";
import ModalPagarOrdenDeVenta from "../OrdenDeVenta/Includes/ModalPagarOrdenDeVenta";
import ModalAccionPDF from "../OrdenDeVenta/Includes/ModalAccionPDF";

import FiltrosPreCotizacion from "./Includes/FiltrosPreCotizacion";
import {DateFormat} from "../../../settings/DateFormat/DateFormat";
import VistaVacia from "../../../componets/Include/VistaVacia/VistaVacia";
import Paginacion from "../../Include/Paginacion/Paginacion";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {CONFIG} from "../../../settings/Config/Config";


const formulario = {
	id_venta: '',
	id_usuario: '',
	venta_producto_disenador_asignado: []
};

const formulariopagos = {
	id_venta: '',
	id_cat_forma_pago: '',
	venta_total: '',
	monto: '',
	pago_con: '',
	numero_referencia: '',
	folio: '',
	clave_rastreo: '',
	cuenta_origen: '',
	cantidad: '',
	
	productos: '',
};

class PreCotizacion extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			
			filtro: {},
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			open: false,
			
			modal: {
				open: false,
				title: '',
				subtitle: '',
				tipo: ''
			},
			
			form: formulario,
			
			modalpagos: {
				open: false,
				title: '',
				subtitle: '',
				tipo: ''
			},
			
			formpagos: formulariopagos,
			
			id_venta_pagar_exportar: null,
			cliente_pagar_exportar: {},
			
			ws_lista_pagos: [],
			ws_por_pagar: 0,
			ws_total_pagado: 0,
			ws_total_venta: 0,
			ws_descuento_venta: 0,
			ws_anticipo_completo: false,
			ws_numero_pedido: '',
			
			productos_anticipo: [],
			
			cat_usuario_disenador: [],
			listar_ventas: [],
			
			ids_expandido: [],
			
			tipo_vista: props.tipo_vista,
		};
		
		this.Listar();
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChangeForm = (e) => {
		const {value, name, checked, type} = e.target;
		let form = this.state.form || {};
		form[name] = type === 'checkbox' ? checked : value;
		this.setState({
			form: form
		});
	};
	
	handleChangePago = (e) => {
		let formpagos = this.state.formpagos || {};
		const {value, name, checked, type} = e.target;
		formpagos[name] = type === 'checkbox' ? checked : value;
		this.setState({
			formpagos: formpagos
		});
	};
	
	handleChangePagoIdCatFormaPago = (v, n) => {
		let desglose = this.updateDesglose(null, {});
		let formpagos = FieldsJs.Copy(formulariopagos) || {};
		formpagos[n] = v;
		formpagos["descuento_venta"] = desglose.descuento;
		formpagos["total_venta"] = desglose.total;
		formpagos["productos"] = desglose.productos;
		formpagos["id_venta"] = this.state.id_venta_pagar_exportar;
		this.setState({
			formpagos: formpagos
		});
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				let key = Number(name.split('__')[1]);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	Listar = () => {
		VentasService.Listar(this.state.filtro, this.state.paginacion, this.state.tipo_vista).then((response) => {
			
			let listar_ventas = response.data;
			let ids_expandido = this.state.ids_expandido || [];
			
			for (let i = 0; i < listar_ventas.length; i++) {
				for (let j = 0; j < ids_expandido.length; j++) {
					if (Number(ids_expandido[j].id_venta) === Number(listar_ventas[i].id_venta)) {
						listar_ventas[i].expandir = true;
					}
				}
			}
			
			this.setState({
				listar_ventas: listar_ventas,
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
			
		}).catch((error) => {
			
			this.setState({listar_ventas: []});
			
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	Eliminar = (item) => {
		
		let msg = `¿Deseas eliminar el ventas ${item.ventas}?`;
		
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Eliminar') {
				VentasService.Eliminar(item.id_venta).then((response) => {
					
					this.showSnackBars('success', response.mensaje);
					
					this.Listar();
					
				}).catch((error) => {
					
					this.showSnackBars('error', error.mensaje);
					
				});
			}
			
		});
	};
	
	nuevaordenprecotizacion = () => {
		$State.go(this.props, 'precotizacion', {})
	};
	
	exportar = (item, archivo, auth) => {
		if (item.id_venta > 0) {
			let tipo = 2;
			HttpRequest.export('vxid', ['ventas'], {
				id_venta: item.id_venta,
				tipo: tipo,
			}, !(tipo === 3))
		} else {
			let fecha_alta_inicio = (this.state.filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_inicio) : null;
			let fecha_alta_fin = (this.state.filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_alta_fin) : null;
			let fecha_entrega_inicio = (this.state.filtro.fecha_entrega_inicio || null) ? DateFormat.FormatSql(this.state.filtro.fecha_entrega_inicio) : null;
			let fecha_entrega_fin = (this.state.filtro.fecha_entrega_fin || null) ? DateFormat.FormatSql(this.state.filtro.fecha_entrega_fin) : null;
			let filtro = {
				numero_pre_cotizacion: this.state.filtro.numero_pre_cotizacion || null,
				numero_cotizacion: this.state.filtro.numero_cotizacion || null,
				numero_pedido: this.state.filtro.numero_pedido || null,
				cliente: this.state.filtro.cliente || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_entrega_inicio: fecha_entrega_inicio,
				fecha_entrega_fin: fecha_entrega_fin,
				status_pago: this.state.filtro.status_pago || null,
				status_proceso: this.state.filtro.status_proceso || null,
				status_tipo_pedido: this.state.filtro.status_tipo_pedido || null,
				id_usuario_disenador: this.state.filtro.id_usuario_disenador || null,
				id_cat_grupo: this.state.filtro.id_cat_grupo || null,
				ordenar_fecha_alta: this.state.filtro.ordenar_fecha_alta || null,
				ordenar_fecha_entrega: this.state.filtro.ordenar_fecha_entrega || null,
				tipo_vista: this.state.tipo_vista || null,
			};
			HttpRequest.export('v', ['ventas'], {
				auth: auth,
				archivo: archivo,
				filtro: filtro
			}, false, false, false, archivo === "EXCEL")
		}
	};
	
	modalDisenador = (item, value) => {
		
		let id_venta_producto;
		
		if (FieldsJs.Array(value)) {
			if (FieldsJs.Field(value.id_venta_producto)) {
				id_venta_producto = value.id_venta_producto;
			}
		} else {
			value = {};
		}
		
		VentasService.VentasDatosDisenador(item.id_venta, id_venta_producto).then((response) => {
			
			CatService.ListUsuarioDisenador().then((cat) => {
				this.setState({
					cat_usuario_disenador: cat.data
				})
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
			
			let venta_producto_disenador_asignado = [];
			
			if (FieldsJs.Array(response.data.disenadores)) {
				venta_producto_disenador_asignado = response.data.disenadores || [];
			}
			
			this.openModal(1, "disenador", {
				id_venta: item.id_venta,
				id_venta_producto: id_venta_producto,
				id_cat_venta_producto_status_proceso: value.id_cat_venta_producto_status_proceso,
				venta_producto_disenador_asignado: venta_producto_disenador_asignado || []
			});
			
		}).catch((error) => {
			console.error(error);
			this.showSnackBars('error', error.mensaje);
			
		});
	};
	
	add = () => {
		if (this.state.form.id_usuario) {
			let item = {};
			for (let i = 0; i < this.state.cat_usuario_disenador.length; i++) {
				if (Number(this.state.cat_usuario_disenador[i].id_usuario) === Number(this.state.form.id_usuario)) {
					item = this.state.cat_usuario_disenador[i];
				}
			}
			if (FieldsJs.Array(item)) {
				let form = FieldsJs.Copy(this.state.form);
				let existe = false;
				for (let i = 0; i < form.venta_producto_disenador_asignado.length; i++) {
					if (Number(form.venta_producto_disenador_asignado[i].id_usuario) === Number(item.id_usuario)) {
						existe = true;
					}
				}
				if (existe === false) {
					form.venta_producto_disenador_asignado.push(item);
					this.setState({
						form: form
					})
				} else {
					this.showSnackBars('error', "El diseñador ya fue seleccionado");
				}
			}
		}
	};
	
	asignar = (id_venta, id_venta_producto, venta_producto_disenador_asignado) => {
		VentasService.VentasAsignarDisenador(id_venta, id_venta_producto, venta_producto_disenador_asignado).then((response) => {
			this.closeModal(1);
			this.showSnackBars('success', response.mensaje);
			this.Listar();
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	produccion = (item, value) => {
		
		let msg;
		let id_venta_producto;
		if (value === null) {
			msg = `¿Deseas enviar todas las partidas de la orden de venta ${item.numero_pedido} a producción?`;
			id_venta_producto = null;
		} else {
			msg = `¿Deseas enviar a producción el producto ${value.venta_producto} de la orden ${item.numero_pedido}?`;
			id_venta_producto = value.id_venta_producto;
		}
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				VentasService.VentasEviarProduccion(item.id_venta, id_venta_producto).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.Listar();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		});
		
	};
	
	/*almacen = (item, value) => {
		
		let msg;
		let id_venta_producto;
		if (value === null) {
			msg = `¿Deseas enviar todas las partidas de la orden de venta ${item.numero_pedido} a almacén?`;
			id_venta_producto = null;
		} else {
			msg = `¿Deseas enviar a almacén el producto ${value.venta_producto} de la orden ${item.numero_pedido}?`;
			id_venta_producto = value.id_venta_producto;
		}
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				VentasService.VentasEviarAlmacen(item.id_venta, id_venta_producto).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.Listar();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		});
		
	};
	
	entregar = (item, value) => {
		let msg;
		let id_venta_producto;
		if (value === null) {
			msg = `¿Confirmas la entrega total de la orden de venta ${item.numero_pedido} al cliente?`;
			id_venta_producto = null;
		} else {
			msg = `¿Confirmas la entrega del ${value.venta_producto} de la orden ${item.numero_pedido}?`;
			id_venta_producto = value.id_venta_producto;
		}
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				VentasService.VentasEntregarPedido(item.id_venta, id_venta_producto).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.Listar();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		});
		
	};*/
	
	cancelar = (item) => {
		let msg = `¿Deseas cancelar esta orden de venta del cliente ${item.cliente_nombre_completo} por el monto?`;
		
		PopupService.Confirm(['Cerrar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				VentasService.VentasCancelarOrden(item).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.Listar();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		});
		
	};
	
	activar = (item) => {
		let msg = `¿Deseas activar nuevamente esta orden de venta del cliente ${item.cliente_nombre_completo} por el monto?`;
		
		PopupService.Confirm(['Cerrar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			
			if (r.button === 'Aceptar') {
				VentasService.VentasActivarOrden(item).then((response) => {
					this.showSnackBars('success', response.mensaje);
					this.Listar();
				}).catch((error) => {
					this.showSnackBars('error', error.mensaje);
				});
			}
			
		});
		
	};
	
	total_descuento = (total, porcentaje_descuento_por_producto, porcentaje_descuento_total_venta) => {
		return 0;
		total = Number(total);
		porcentaje_descuento_por_producto = Number(porcentaje_descuento_por_producto);
		porcentaje_descuento_total_venta = Number(porcentaje_descuento_total_venta);
		if (porcentaje_descuento_por_producto > 0) {
			return (porcentaje_descuento_por_producto * total) / 100;
		} else if (porcentaje_descuento_total_venta > 0) {
			return (porcentaje_descuento_total_venta * total) / 100;
		} else {
			return 0;
		}
	};
	
	updateDesglose = (productos, venta) => {
		let prod = [];
		if (productos) {
			prod = FieldsJs.Copy(productos) || [];
		} else {
			prod = FieldsJs.Copy(this.state.formpagos.productos) || [];
		}
		let total = 0;
		let descuento = 0;
		let iva = 16;
		for (let i = 0; i < prod.length; i++) {
			let item = prod[i];
			let total_producto_actual = 0;
			if (item.venta_cantidad > 0 && item.venta_precio_unitario > 0) {
				
				total_producto_actual += (item.venta_cantidad * item.venta_precio_unitario);
				
				for (let j = 0; j < item.venta_producto_tipo_requerimiento.length; j++) {
					total_producto_actual += (item.venta_producto_tipo_requerimiento[j].costo_adicional || 0)
				}
				
				for (let j = 0; j < item.venta_producto_requerimiento_adicional.length; j++) {
					total_producto_actual += (item.venta_producto_requerimiento_adicional[j].costo_adicional || 0)
				}
				
				total += total_producto_actual;
				
				prod[i].venta_descuento = this.total_descuento(total_producto_actual, (item.venta_porcentaje_descuento || 0), (venta.venta_porcentaje_descuento || 0));
				
				descuento += prod[i].venta_descuento;
			}
		}
		let subtotal = (total / ((iva / 100) + 1));
		return {
			iva: iva,
			subtotal: subtotal,
			iva_total: total - subtotal,
			total: total - descuento,
			descuento: descuento,
			productos: prod,
		};
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_ventas', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_venta: item.id_venta
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_venta) !== Number(item.id_venta)) {
					ids_expandido.push({
						id_venta: ids_temp[i].id_venta
					})
				}
			}
			
		}
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
		// if (!!!item.expandir) {
		// 	OrdenDeVentaService.OrdenDeVentaDatos(item.id_venta).then((response) => {
		// 		this.ShowSubTabla(item, key);
		//
		// 	}).catch((error) => {
		// 		this.showSnackBars('error', error.mensaje);
		// 	});
		// } else {
		// 	this.ShowSubTabla(item, key);
		// }
	};
	
	OrdenDeVentaContinuar = (item) => {
		OrdenDeVentaService.OrdenDeVentaDatos(item.id_venta).then((response) => {
			let productos = FieldsJs.Copy(response.data.venta.venta_producto);
			item.productos = productos || [];
			item.pagos = response.data.pagos;
			this.openModal(true, 'modalpagos', item);
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	watchHorasTrabajadas = () => {
		this.Listar();
	};
	
	watchAsignarCliente = () => {
		this.Listar();
	};
	
	watchEnviarTodoAlmacen = () => {
		this.Listar();
	};
	
	watchEnviarTodoCliente = () => {
		this.Listar();
	};
	
	watchEnviarAlmacen = () => {
		this.Listar();
	};
	
	watchEnviarCliente = () => {
		this.Listar();
	};
	
	watchAprobarCotizacion = () => {
		this.Listar();
	};
	
	Pagar = (tab) => {
		this.OrdenDeVentaPagar(this.state.formpagos.id_venta, this.state.formpagos, tab);
	};
	
	OrdenDeVentaPagar = (id_venta, data, tab) => {
		OrdenDeVentaService.OrdenDeVentaPagar(id_venta, data, this.state.productos_anticipo, tab).then((response) => {
			let info = response.data || [];
			
			let productos_anticipo = info.venta_producto;
			
			let arr_temp = this.state.productos_anticipo;
			for (let i = 0; i < productos_anticipo.length; i++) {
				if (productos_anticipo[i].cubrir_anticipo === 0) {
					productos_anticipo[i].cubrir_anticipo_checked = false;
					for (let j = 0; j < arr_temp.length; j++) {
						if (arr_temp[j].id_venta_producto === productos_anticipo[i].id_venta_producto) {
							productos_anticipo[i].cubrir_anticipo_checked = (arr_temp[j].cubrir_anticipo_checked || false);
						}
					}
				} else {
					productos_anticipo[i].cubrir_anticipo_checked = false;
				}
				if (productos_anticipo[i].cubrir_total === 0) {
					productos_anticipo[i].cubrir_total_checked = false;
					for (let j = 0; j < arr_temp.length; j++) {
						if (arr_temp[j].id_venta_producto === productos_anticipo[i].id_venta_producto) {
							productos_anticipo[i].cubrir_total_checked = (arr_temp[j].cubrir_total_checked || false);
						}
					}
				} else {
					productos_anticipo[i].cubrir_total_checked = false;
				}
			}
			
			this.setState({
				ws_lista_pagos: info.pagos || [],
				ws_por_pagar: info.por_pagar || 0,
				ws_total_pagado: info.total_pagado || 0,
				ws_descuento_venta: info.descuento_venta || 0,
				ws_anticipo_completo: info.anticipo_completo || false,
				ws_numero_pedido: info.numero_pedido || '',
				
				productos_anticipo: productos_anticipo,
			});
			this.handleChangePagoIdCatFormaPago(null, "id_cat_forma_pago");
			
			this.confirmacion(info.anticipo_completo, info.por_pagar);
			
			this.Listar();
		}).catch((error) => {
			this.showSnackBars('error', error.mensaje);
		})
	};
	
	confirmacion = (anticipo_completo, por_pagar) => {
		
		if (anticipo_completo) {
			
			let msg = '';
			let btn = [];
			
			if (por_pagar === 0) {
				msg = `¿Se ha completado el pago de la orden de venta?`;
				btn = [null, 'Ver o exportar orden'];
			} else {
				msg = `¿Se ha completado el 50% o más del pago de la orden de venta?`;
				btn = ['Agregar otro pagos', 'Ver o exportar orden'];
			}
			
			PopupService.Confirm(btn, 'success', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === btn[0]) {
				}
				
				if (r.button === btn[1]) {
					this.VerExportarOrden();
				}
				
			});
		}
	};
	
	VerExportarOrden = () => {
		this.openModal();
	};
	
	openModal = (index, tipo, item) => {
		
		if (index) {
			let modal = "";
			let form = "";
			let modal_settings = {};
			let modal_formulario = {};
			
			switch (tipo) {
				case 'disenador':
					modal = "modal";
					form = "form";
					modal_settings = {
						open: true,
						title: "Asignar diseñador",
						subtitle: "",
						tipo: tipo
					};
					modal_formulario = {
						id_venta: item.id_venta || '',
						id_venta_producto: item.id_venta_producto || '',
						id_cat_venta_producto_status_proceso: item.id_cat_venta_producto_status_proceso || '',
						id_usuario: '',
						venta_producto_disenador_asignado: item.venta_producto_disenador_asignado || [],
					};
					
					this.setState({
						[modal]: modal_settings,
						[form]: modal_formulario,
					});
					
					break;
				case 'modalpagos':
					modal = "modalpagos";
					form = "formpagos";
					modal_settings = {
						open: true,
						title: "Formas de pago",
						subtitle: "",
						tipo: tipo
					};
					
					let desglose = this.updateDesglose(item.productos, item); // se abre por primera vez los productos parámetro null
					
					let productos_anticipo = desglose.productos;
					
					modal_formulario = {
						id_venta: item.id_venta,
						
						id_cat_forma_pago: '',
						descuento_venta: desglose.descuento || '',
						total_venta: desglose.total || '',
						monto: '',
						pago_con: '',
						numero_referencia: '',
						folio: '',
						clave_rastreo: '',
						cuenta_origen: '',
						cantidad: '',
						
						productos: productos_anticipo,
					};
					
					this.setState({
						[modal]: modal_settings,
						[form]: modal_formulario,
						
						id_venta_pagar_exportar: item.id_venta,
						cliente_pagar_exportar: item.cliente,
						
						ws_lista_pagos: item.pagos.pagos || [],
						ws_por_pagar: item.pagos.por_pagar || 0,
						ws_total_pagado: item.pagos.total_pagado || 0,
						ws_total_venta: item.pagos.total_venta || 0,
						ws_descuento_venta: item.pagos.descuento_venta || 0,
						ws_anticipo_completo: item.pagos.anticipo_completo || false,
						ws_numero_pedido: item.pagos.numero_pedido || '',
						
						productos_anticipo: FieldsJs.Copy(productos_anticipo),
					});
					
					if (tipo === "modalpagos") {
						setTimeout(() => {
							this.toggle_linea_produccion(false);
						}, 700);
					}
					
					break;
				default:
			}
		} else {
			this.setState({open: true});
		}
		
	};
	
	closeModal = (index, confirmacion) => {
		if (confirmacion && this.state.ws_anticipo_completo === false) {
			
			let msg = `No se ha completado el pago del 50% de la orden de venta ¿Deseas cerrar la ventana formas de pago?`;
			let btn = ['Cerrar', 'Realizar otro pago'];
			
			PopupService.Confirm(btn, 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				
				if (r.button === btn[0]) {
					if (index) {
						this.setState({
							modal: {
								open: false,
								title: '',
								subtitle: '',
								tipo: ''
							},
							modalpagos: {
								open: false,
								title: '',
								subtitle: '',
								tipo: ''
							}
						})
					} else {
						this.setState({open: false});
					}
				}
				
				if (r.button === btn[1]) {
				}
				
			});
		} else {
			if (index) {
				this.setState({
					modal: {
						open: false,
						title: '',
						subtitle: '',
						tipo: ''
					},
					modalpagos: {
						open: false,
						title: '',
						subtitle: '',
						tipo: ''
					}
				})
			} else {
				this.setState({open: false});
			}
		}
	};
	
	toggle_linea_produccion = (ver_producto_anticipi_linea_produccion) => {
		
		let productos_anticipo = this.state.productos_anticipo;
		
		if (ver_producto_anticipi_linea_produccion) {
			for (let i = 0; i < productos_anticipo.length; i++) {
				productos_anticipo[i].cubrir_anticipo_checked = false;
				productos_anticipo[i].cubrir_total_checked = false;
			}
			let formpagos = this.state.formpagos;
			formpagos.productos_anticipo = productos_anticipo;
			this.setState({
				formpagos: formpagos,
				productos_anticipo: productos_anticipo
			})
		} else {
			for (let i = 0; i < productos_anticipo.length; i++) {
				if (productos_anticipo[i].cubrir_total === 1) {
					productos_anticipo[i].cubrir_anticipo_checked = false;
					productos_anticipo[i].cubrir_total_checked = false;
				} else {
					productos_anticipo[i].cubrir_anticipo_checked = false;
					productos_anticipo[i].cubrir_total_checked = true;
				}
			}
			let formpagos = this.state.formpagos;
			formpagos.productos_anticipo = productos_anticipo;
			this.setState({
				formpagos: formpagos,
				productos_anticipo: productos_anticipo
			})
		}
	};
	
	DesactivarFacturaNotaSencillaInterno = (option, item) => {
		let btn = ['Cancelar', 'Aceptar'];
		let msg = '';
		let data = {};
		switch (option.name) {
			case 'desactivar_factura':
				msg = '¿Deseas desactivar que el cliente requiere una factura?';
				data = {
					id_venta: item.id_venta,
					factura: 0
				};
				break;
			case 'desactivar_nota_sencilla':
				msg = '¿Deseas desactivar la nota sencilla?';
				data = {
					id_venta: item.id_venta,
					nota_sencilla: 0
				};
				break;
			case 'desactivar_interno':
				msg = '¿Deseas desactivar la orden de tipo interno?';
				data = {
					id_venta: item.id_venta,
					interno: 0
				};
				break;
			case 'solicitar_edicion':
				msg = '¿Deseas solicitar la edición de esta venta?';
				data = {
					id_venta: item.id_venta,
					edicion: "Solicitar"
				};
				break;
			case 'cancelar_solicitud_de_edicion':
				msg = '¿Deseas cancelar la solicitud de edición?';
				data = {
					id_venta: item.id_venta,
					edicion: "Sin accion"
				};
				break;
			case 'aprobar_edicion':
				msg = '¿Deseas aprobar la edición de esta venta?';
				data = {
					id_venta: item.id_venta,
					edicion: "Aprobado"
				};
				break;
			case 'cancelar_edicion':
				msg = '¿Deseas cancelar la aprobación para editar esta venta?';
				data = {
					id_venta: item.id_venta,
					edicion: "Sin accion"
				};
				break;
			case 'finalizar_edicion':
				msg = '¿Deseas finalizar la edición de esta venta?';
				data = {
					id_venta: item.id_venta,
					edicion: "Sin accion"
				};
				break;
			default:
				return;
		}
		PopupService.Confirm(btn, 'info', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === btn[1]) {
				switch (option.name) {
					case 'desactivar_factura':
						VentasService.ActivarDesactivarFactura(data).then(value => {
							this.showSnackBars('success', value.mensaje);
							this.Listar();
						}).catch(reason => {
							this.showSnackBars('error', reason.mensaje);
						});
						break;
					case 'desactivar_nota_sencilla':
						VentasService.ActivarDesactivarNotaSencilla(data).then(value => {
							this.showSnackBars('success', value.mensaje);
							this.Listar();
						}).catch(reason => {
							this.showSnackBars('error', reason.mensaje);
						});
						break;
					case 'desactivar_interno':
						VentasService.ActivarDesactivarInterno(data).then(value => {
							this.showSnackBars('success', value.mensaje);
							this.Listar();
						}).catch(reason => {
							this.showSnackBars('error', reason.mensaje);
						});
						break;
					case 'solicitar_edicion':
					case 'cancelar_solicitud_de_edicion':
					case 'aprobar_edicion':
					case 'cancelar_edicion':
					case 'finalizar_edicion':
						VentasService.SolicitarAprobarEdicion(data).then(value => {
							this.showSnackBars('success', value.mensaje);
							this.Listar();
						}).catch(reason => {
							this.showSnackBars('error', reason.mensaje);
						});
						break;
					default:
				}
			}
		});
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<Fragment>
					
					<ModalDisenador
						modal={this.state.modal} form={this.state.form} save={this.save}
						closeModal={this.closeModal} handleChange={this.handleChangeForm}
						cat_usuario_disenador={this.state.cat_usuario_disenador} add={this.add}
						asignar={this.asignar}
					/>
				
				</Fragment>
				
				<Fragment>
					
					<ModalPagarOrdenDeVenta
						modal={this.state.modalpagos} form={this.state.formpagos} Pagar={this.Pagar}
						closeModal={this.closeModal} handleChangePago={this.handleChangePago}
						handleChangePagoIdCatFormaPago={this.handleChangePagoIdCatFormaPago}
						pagos={{
							ws_lista_pagos: this.state.ws_lista_pagos,
							ws_por_pagar: this.state.ws_por_pagar,
							ws_total_pagado: this.state.ws_total_pagado,
							ws_total_venta: this.state.ws_total_venta,
							ws_descuento_venta: this.state.ws_descuento_venta,
							ws_anticipo_completo: this.state.ws_anticipo_completo || false,
							ws_numero_pedido: this.state.ws_numero_pedido || '',
						}}
						VerExportarOrden={this.VerExportarOrden}
						
						productos_anticipo={this.state.productos_anticipo}
						handleChange={this.handleChange}
						toggle_linea_produccion={this.toggle_linea_produccion}
						
						id_venta_pagar_exportar={this.state.id_venta_pagar_exportar}
					/>
				
				</Fragment>
				
				<Fragment>
					
					<ModalAccionPDF
						open={this.state.open}
						closeModal={this.closeModal}
						showSnackBars={this.showSnackBars}
						id_venta={this.state.id_venta_pagar_exportar}
						cliente={this.state.cliente_pagar_exportar}
					/>
				
				</Fragment>
				
				<div className={'row-flex margin-30-T margin-30-B'}>
					<div className={'w-100-300-px'}>
						<Typography variant={'h5'} className={'text-left'}>
							{this.state.tipo_vista === 'cotizacion' ? 'Cotizaciones' : ''}
							{this.state.tipo_vista === 'nota_sencilla|factura|interna' ? 'Ordenes de ventas' : ''}
						</Typography>
					</div>
					<div className={'w-300-px text-right'}>
						<Fab variant="extended" size="small" color="primary" aria-label="PDF"
						     className={'margin-10-L bg-danger'} onClick={() => this.exportar({}, 'PDF', true)}>
							<PictureAsPdf className={'margin-5-R px-14'}/>
							PDF
						</Fab>
						<Fab variant="extended" size="small" color="primary" aria-label="Excel"
						     className={'margin-10-L bg-green'} onClick={() => this.exportar({}, 'EXCEL', false)}>
							<EventNote className={'margin-5-R px-14'}/>
							Excel
						</Fab>
						<Fab variant="extended" size="small" color="primary" aria-label="Add"
						     className={'margin-10-L'} onClick={this.Listar}>
							<Sync className={'margin-5-R px-14'}/>
							Actualizar
						</Fab>
					</div>
				</div>
				
				<FiltrosPreCotizacion
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
					tipo_vista={this.props.tipo_vista}
				/>
				
				<div className={'form margin-30-B'}>
					
					{this.state.listar_ventas.length > 0 ? (
						<Fragment>
							<ListaTabla
								lista={this.state.listar_ventas}
								exportar={this.exportar}
								modalDisenador={this.modalDisenador}
								produccion={this.produccion}
								// almacen={this.almacen}
								// entregar={this.entregar}
								cancelar={this.cancelar}
								activar={this.activar}
								OrdenDeVentaContinuar={this.OrdenDeVentaContinuar}
								
								watchAsignarCliente={this.watchAsignarCliente}
								watchEnviarTodoAlmacen={this.watchEnviarTodoAlmacen}
								watchEnviarTodoCliente={this.watchEnviarTodoCliente}
								watchHorasTrabajadas={this.watchHorasTrabajadas}
								watchEnviarAlmacen={this.watchEnviarAlmacen}
								watchEnviarCliente={this.watchEnviarCliente}
								watchAprobarCotizacion={this.watchAprobarCotizacion}
								
								handleChange={this.handleChange}
								SubTabla={this.SubTabla}
								showSnackBars={this.showSnackBars}
								
								DesactivarFacturaNotaSencillaInterno={this.DesactivarFacturaNotaSencillaInterno}
								
								tipo_vista={this.props.tipo_vista}
							/>
							<Paginacion
								total={this.state.paginacion.total}
								page={this.state.paginacion.page}
								limit={this.state.paginacion.limit}
								rangos={this.state.paginacion.rangos}
								onClick={(data) => this.AplicarPaginacion(data)}
							/>
						</Fragment>
					
					) : (
						<VistaVacia numero={1} mensaje={'No se encontraron órdenes de venta'}/>
					)}
				
				</div>
				
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.nuevaordenprecotizacion()}>
						<Add/>
					</Fab>
				</Zoom>
			
			</div>
		);
	}
}

PreCotizacion.propTypes = {
	tipo_vista: PropTypes.string.isRequired,
};

export default IntegrationNotistack(PreCotizacion);
