import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const ComprasService = {
	Listar: (filtro, paginacion) => {
		let fecha_alta_inicio = (filtro.fecha_alta_inicio || null) ? DateFormat.FormatSql(filtro.fecha_alta_inicio) : null;
		let fecha_alta_fin = (filtro.fecha_alta_fin || null) ? DateFormat.FormatSql(filtro.fecha_alta_fin) : null;
		let fecha_compra_inicio = (filtro.fecha_compra_inicio || null) ? DateFormat.FormatSql(filtro.fecha_compra_inicio) : null;
		let fecha_compra_fin = (filtro.fecha_compra_fin || null) ? DateFormat.FormatSql(filtro.fecha_compra_fin) : null;
		
		let params = {
			filtro: {
				numero_compra: filtro.numero_compra || null,
				usuario: filtro.usuario || null,
				proveedor: filtro.proveedor || null,
				fecha_alta_inicio: fecha_alta_inicio,
				fecha_alta_fin: fecha_alta_fin,
				fecha_compra_inicio: fecha_compra_inicio,
				fecha_compra_fin: fecha_compra_fin,
				ordenar_fecha_alta: filtro.ordenar_fecha_alta || null,
				ordenar_fecha_compra: filtro.ordenar_fecha_compra || null
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Detalles: (id_compra) => {
		let params = {
			id_compra: id_compra,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Detalle', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Guardar: (form) => {
		let ws = '_Compras_Agregar';
		let id_compra = null;
		if (form.id_compra > 0) {
			ws = '_Compras_Modificar';
			id_compra = form.id_compra;
		}
		let fecha_compra = DateFormat.FormatSql(form.fecha_compra);
		let params = {
			id_compra: id_compra,
			id_cat_tipo_comprobante: form.id_cat_tipo_comprobante,
			id_cat_forma_pago: form.id_cat_forma_pago,
			id_proveedor: form.id_proveedor,
			quien_recibio: form.quien_recibio,
			
			descripcion: form.descripcion || null,
			fecha_compra: fecha_compra || null,
			total: form.total || null,
			compra_producto: form.lista_compra_producto || [],
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post(ws, params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_compra) => {
		let params = {
			id_compra: id_compra
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SubirComprobante: (form) => {
		let params = {
			id_compra: form.id_compra,
			comprobante_archivo: form.comprobante_archivo,
			comprobante_formato: form.comprobante_formato,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Compras_Subir_Comprobante', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
