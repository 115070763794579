import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {PlanesService} from "../../../../services/PlanesService/PlanesService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import AutoCompleteCliente from "../../OrdenDeVenta/Includes/AutoCompleteCliente";
import {ClienteService} from "../../../../services/ClienteService/ClienteService";
import {PlanService} from "../../../../services/_Cat/PlanService/PlanService";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import moment from "moment";


const formulario = {
	id_cliente: null,
	id_cat_tipo_persona: 1,
	tipo_persona: 'fisica',
	razon_social: '',
	nombre: '',
	apellido_paterno: '',
	apellido_materno: '',
	correo_electronico: '',
	telefono: '',
	celular: '',
	rfc: '',
	activo: true,
	
	id_cliente_direccion: null,
	calle: '',
	numero_exterior: '',
	numero_interior: '',
	codigo_postal: '',
	colonia: '',
	id_cat_estado: '',
	id_cat_municipio: '',
	estado: '',
	municipio: '',
	
	ids_cliente_como_te_enteraste: []
};


class ModalPlanes extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_plan: (props.item || {}).id_plan,
			
			id_cat_plan: '',
			id_cliente: '',
			fecha_inicio: moment(new Date()),
			fecha_fin: moment(new Date()),
			
			activo: true,
			
			
			form: formulario,
			form_seleccionado: {},
			
			clientes_datos_suggestions: [],
			
			list_cat_plan: []
			
		};
		
		this.ListarDeCliente();
		this.ListarPlanes();
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListarDeCliente = () => {
		ClienteService.Listar({activo: 1}, {}).then((response) => {
			
			let clt = response.data;
			
			let form = this.state.form;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form.id_cliente)) {
					form = clt[i];
				}
			}
			
			let form_seleccionado = this.state.form_seleccionado;
			for (let i = 0; i < clt.length; i++) {
				if (Number(clt[i].id_cliente) === Number(form_seleccionado.id_cliente)) {
					form_seleccionado = clt[i];
				}
			}
			
			this.setState({
				clientes_datos_suggestions: clt,
				form: form,
				form_seleccionado: form_seleccionado,
			});
		});
	};
	
	ListarPlanes = () => {
		PlanService.Listar().then((response) => {
			this.setState({
				list_cat_plan: response.data
			});
		});
	};
	
	
	save = () => {
		if (this.state.id_plan > 0) {
			PlanesService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			PlanesService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_plan: '',
			id_cat_plan: '',
			id_cliente: '',
			fecha_inicio: moment(new Date()),
			fecha_fin: moment(new Date()),
			
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_plan: item.id_plan || '',
			id_cat_plan: item.id_cat_plan || '',
			id_cliente: item.id_cliente || '',
			fecha_inicio: item.fecha_inicio || moment(new Date()),
			fecha_fin: item.fecha_fin || moment(new Date()),
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_plan: item.id_plan || '',
			id_cat_plan: item.id_cat_plan || '',
			id_cliente: item.id_cliente || '',
			fecha_inicio: item.fecha_inicio || moment(new Date()),
			fecha_fin: item.fecha_fin || moment(new Date()),
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	handleChangeSingle = (e, name) => {
		console.log(name, e);
		
		let form = FieldsJs.Copy(formulario);
		
		for (let i = 0; i < this.state.clientes_datos_suggestions.length; i++) {
			if (this.state.clientes_datos_suggestions[i].id_cliente === e.value) {
				for (let key in formulario) {
					form[key] = this.state.clientes_datos_suggestions[i][key];
				}
			}
		}
		
		this.setState({
			[name]: e.value,
			form: FieldsJs.Copy(form),
			form_seleccionado: FieldsJs.Copy(form)
		});
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'sexo'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<AutoCompleteCliente
									handleChangeSingle={this.handleChangeSingle}
									suggestions={this.state.clientes_datos_suggestions}
									value={this.state.id_cliente}
									name={'id_cliente'} id={'id_cliente'}
									label={'nombre_completo'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									name="id_cat_plan"
									label="Plan"
									value={this.state.id_cat_plan}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_plan.map(option => (
										<option key={option.id_cat_plan} value={option.id_cat_plan}>
											{option.nombre}
										</option>
									))}
								</TextField>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Divider/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd" fullWidth required clearable
									inputProps={{
										readOnly: true,
										placeholder: "Fecha / hora inicio"
									}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha / hora inicio',
									}}
									value={this.state.fecha_inicio}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_inicio');
									}}
									disabled={this.state.tipo === 'view'}
									minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<KeyboardDateTimePicker
									format="yyyy/MM/dd" fullWidth required clearable
									inputProps={{
										readOnly: true,
										placeholder: "Fecha / hora inicio"
									}}
									KeyboardButtonProps={{
										'aria-label': 'Fecha / hora inicio',
									}}
									value={this.state.fecha_fin}
									onChange={(date) => {
										this.handleChange(null, null, null, date, 'fecha_fin');
									}}
									disabled={this.state.tipo === 'view'}
									minDate={moment(new Date())}
									clearLabel={'Limpiar'}
									okLabel={'Aceptar'}
									cancelLabel={'Cancelar'}
									onError={console.log}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPlanes.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPlanes;
