import React, {Component, Fragment} from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";


class FormPagoEfectivo extends Component {
	
	cambio = () => {
		if (Number(this.props.form.monto) > Number(this.props.form.pago_con)) {
			return 0;
		} else {
			return this.props.form.pago_con - this.props.form.monto;
		}
	};
	
	render() {
		
		const cambio_total = this.cambio();
		
		return (
			<Fragment>
				<div className={'borde-punteado-gray-1 padding-15'}>
					<Grid container spacing={1}>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"} align={'center'}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Importe Total
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="monto"
									           value={this.props.form.monto}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 15,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							
							<Grid container spacing={1} alignItems={"flex-end"}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography className={'px-14 text-center'}>
										Con cuánto paga
									</Typography>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<TextField type={'text'} fullWidth variant={"filled"} margin={"none"}
									           onChange={this.props.handleChangePago}
									           name="pago_con"
									           value={this.props.form.pago_con}
									           onKeyPress={EnteroSolo}
									           inputProps={{
										           maxLength: 15,
										           style: {
											           paddingTop: "8px"
										           }
									           }}
									/>
								</Grid>
							</Grid>
						
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography className={'px-18'}>
								Cambio
							</Typography>
							<Typography className={'px-26'}>
								$ {FieldsJs.Currency(cambio_total)}
							</Typography>
						</Grid>
						
						<Grid item className={'text-center'} xs={12} sm={12} md={12} lg={12} xl={12}>
							<Button variant="contained" color="primary" onClick={this.props.Pagar}
							        className={'btn-default-primary'}>
								Realizar pago
							</Button>
						</Grid>
					
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

FormPagoEfectivo.propTypes = {
	handleChangePago: PropTypes.func.isRequired,
	form: PropTypes.object.isRequired,
	Pagar: PropTypes.func.isRequired,
};

export default FormPagoEfectivo;
