import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {PlanService} from "../../../../../services/_Cat/PlanService/PlanService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../../Include/MiniComponents/GlobalComponent";

class ModalPlan extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cat_plan: (props.item || {}).id_cat_plan,

			id_cat_hora_persona: '',
			nombre: '',
			precio: '',
			dias_gratis: '',
			costo_dia: '',
			costo_hora: '',
			visita_ilimitada: '',
			cant_impresion_bn: '',
			cant_impresion_color: '',
			horas_sala_4p: '',
			horas_sala_6_8p: '',
			horas_sala_12p: '',
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_cat_plan > 0) {
			PlanService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			PlanService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cat_plan: '',
			nombre: '',
			precio: '',
			dias_gratis: '',
			costo_dia: '',
			costo_hora: '',
			visita_ilimitada: '',
			cant_impresion_bn: '',
			cant_impresion_color: '',
			horas_sala_4p: '',
			horas_sala_6_8p: '',
			horas_sala_12p: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_plan: item.id_cat_plan || '',
			nombre: item.nombre || '',
			precio: item.precio || '',
			dias_gratis: item.dias_gratis || '',
			costo_dia: item.costo_dia || '',
			costo_hora: item.costo_hora || '',
			visita_ilimitada: item.visita_ilimitada || '',
			cant_impresion_bn: item.cant_impresion_bn || '',
			cant_impresion_color: item.cant_impresion_color || '',
			horas_sala_4p: item.horas_sala_4p || '',
			horas_sala_6_8p: item.horas_sala_6_8p || '',
			horas_sala_12p: item.horas_sala_12p || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_plan: item.id_cat_plan || '',
			nombre: item.nombre || '',
			precio: item.precio || '',
			dias_gratis: item.dias_gratis || '',
			costo_dia: item.costo_dia || '',
			costo_hora: item.costo_hora || '',
			visita_ilimitada: item.visita_ilimitada || '',
			cant_impresion_bn: item.cant_impresion_bn || '',
			cant_impresion_color: item.cant_impresion_color || '',
			horas_sala_4p: item.horas_sala_4p || '',
			horas_sala_6_8p: item.horas_sala_6_8p || '',
			horas_sala_12p: item.horas_sala_12p || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_plan}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Planes'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="nombre"
									label="Nombre del plan"
									type="text"
									fullWidth
									defaultValue={this.state.nombre}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="precio"
									label="Precio"
									type="text"
									fullWidth
									defaultValue={this.state.precio}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="dias_gratis"
									label="Dias gratis"
									type="text"
									fullWidth
									onKeyPress={EnteroSolo}
									defaultValue={this.state.dias_gratis}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="costo_dia"
									label="Precio por dia"
									type="text"
									fullWidth
									defaultValue={this.state.costo_dia}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="costo_hora"
									label="Precio por hora"
									type="text"
									fullWidth
									defaultValue={this.state.costo_hora}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="horas_sala_4p"
									label="Horas sala 4 personas"
									type="text"
									fullWidth
									defaultValue={this.state.horas_sala_4p}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="horas_sala_6_8p"
									label="Horas sala 6-8 personas"
									type="text"
									fullWidth
									defaultValue={this.state.horas_sala_6_8p}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="horas_sala_12p"
									label="Horas sala 12 personas"
									type="text"
									fullWidth
									defaultValue={this.state.horas_sala_12p}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="cant_impresion_bn"
									label="Cantidad de impresiones b/n"
									type="text"
									fullWidth
									defaultValue={this.state.cant_impresion_bn}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									autoFocus
									margin="dense"
									name="cant_impresion_color"
									label="Cantidad de impresiones a color"
									type="text"
									fullWidth
									defaultValue={this.state.cant_impresion_color}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='visita_ilimitada'
												checked={this.state.visita_ilimitada}
												value="activo"
											/>
										}
										label={'Visitas ilimitadas'}
									/>
								</FormGroup>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_plan}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalPlan.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalPlan;
