import {HttpRequest} from '../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../settings/DateFormat/DateFormat";

export const PlanesService = {
	Listar: (filtro, paginacion, tipo_vista) => {
		let params = {
			filtro: {
				id_plan: filtro.id_plan || null,
				id_cliente: filtro.id_cliente || null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Planes_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_inicio = DateFormat.FormatSql(form.fecha_inicio);
		let fecha_fin = DateFormat.FormatSql(form.fecha_fin);
		
		let params = {
			id_cat_plan: form.id_cat_plan,
			id_cliente: form.id_cliente,
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Planes_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_inicio = DateFormat.FormatSql(form.fecha_inicio);
		let fecha_fin = DateFormat.FormatSql(form.fecha_fin);
		
		let params = {
			id_plan: form.id_plan,
			id_cat_plan: form.id_cat_plan,
			id_cliente: form.id_cliente,
			fecha_inicio: fecha_inicio,
			fecha_fin: fecha_fin,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Planes_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_plan) => {
		let params = {
			id_plan: id_plan
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Planes_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
