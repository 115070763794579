import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const HoraPersonaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Hora_Persona_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_cat_hora_persona: form.id_cat_hora_persona,
			personas: form.personas,
			list_cat_dia: form.list_cat_dia,
			costo: form.costo,
			horas: form.horas,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Hora_Persona_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_cat_hora_persona: form.id_cat_hora_persona,
			personas: form.personas,
			list_cat_dia: form.list_cat_dia,
			costo: form.costo,
			horas: form.horas,
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Hora_Persona_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_cat_hora_persona) => {
		let params = {
			id_cat_hora_persona: id_cat_hora_persona
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cat/_Hora_Persona_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
