import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroEmpresa: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_empresa').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioDisenador: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_disenador').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGrupo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/grupo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListComoTeEnteraste: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/como_te_enteraste').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoReporte: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_reporte').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCategoria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/categoria', {}, {spinner: spinner || false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoComprobante: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_comprobante').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProducto: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/producto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProveedor: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proveedor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPago: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoRequerimiento: (id_cat_grupo) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_requerimiento/' + id_cat_grupo).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGrupoCompra: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/grupo_compra').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoCompra: (spinner, id_cat_grupo_compra) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_compra/'+id_cat_grupo_compra).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMedidaLargoCompra: (spinner, id_cat_grupo_compra) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/medida_largo_compra/'+id_cat_grupo_compra).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMedidaAnchoCompra: (spinner, id_cat_grupo_compra) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/medida_ancho_compra/'+id_cat_grupo_compra).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUnidadMedida: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/unidad_medida').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoCuenta: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_cuenta').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoIngresoFondeo: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_ingreso_fondeo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListFormaPagoFondeo: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/forma_pago_fondeo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEnvioEstatus: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/envio_estatus').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoMensajeria: (spinner) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_mensajeria').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
